import { defineNuxtRouteMiddleware } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to) => {
  // skip middleware on server and for start page, as this redirects to login
  if (process.server || to.path === '/' || to.path === '/env-mismatch') { return }

  const envVars = useRuntimeConfig()
  const { status, data: session } = useAuth()

  // not logged in, do not redirect as it affects keycloak redirect url
  if (status.value === 'unauthenticated') {
    return
  }

  // only if user is member of next_user keycloak group,
  // access to next-* is allowed and vice versa
  // @ts-ignore
  const isNextUser = session?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'next_user'
  )

  // access to demo-* is allowed and vice versa
  // @ts-ignore
  const isDemoUser = session?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'demo_user'
  )

  if ((envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') && isNextUser) {
    return
  }
  if (envVars.public.ENVIRONMENT === 'demo' && isDemoUser) {
    return
  }
  if (envVars.public.ENVIRONMENT === 'prod' && !isNextUser) {
    return
  }

  return navigateTo('/env-mismatch')
})
