<template>
  <SharedErrorView
    :error="error"
    :on-click-method="handleError"
  />
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const route = useRoute()

defineProps({
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })

// on route change clear error
watch(() => route.fullPath, () => clearError(), { immediate: true })
</script>
