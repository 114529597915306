<template>
  <section class="flex-grow">
    <!-- switch to nuxt-img when ssg is supported -->
    <nuxt-img
      v-if="isLocal"
      :alt="alt"
      :width="width"
      fit="in"
      :src="source"
      format="webp"
      loading="lazy"
      :quality="quality"
      :class="imgClasses"
    />
    <nuxt-img
      v-else
      provider="storyblok"
      :alt="alt"
      :width="width"
      fit="in"
      :src="source"
      format="webp"
      loading="lazy"
      :quality="quality"
      :class="imgClasses"
    />
  </section>
</template>

<script>
export default {
  name: 'DefaultImage',
  props: {
    source: {
      type: String,
      required: true
    },
    isLocal: {
      type: Boolean,
      default: true
    },
    alt: {
      type: String,
      default: ''
    },
    imgClasses: {
      type: String,
      default: ''
    },
    quality: {
      type: Number,
      default: 100
    },
    width: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
