import { authExchange, type AuthUtilities } from '@urql/exchange-auth'
import {
  Client,
  CombinedError,
  createClient,
  fetchExchange,
  subscriptionExchange,
  type Exchange,
  type Operation,
  type OperationResult,
  type SubscriptionOperation
} from '@urql/core'
import { pipe, subscribe, type Subscription } from 'wonka'
import { defineNuxtPlugin, type NuxtApp } from 'nuxt/app'
import { retryExchange } from '@urql/exchange-retry'
import { devtoolsExchange } from '@urql/devtools'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { createClient as createWSClient } from 'graphql-ws'
import { getMainRole } from '~/utils/other'

type AuthData = {
  session: any;
  role: string;
}
async function initializeAuthState (): Promise<AuthData> {
  const headers = useRequestHeaders(['cookie']) as HeadersInit
  const { data: session } = await useFetch('/api/auth/session', { headers })
  const role = getMainRole(
    session.value?.user?.roles?.['x-hasura-allowed-roles']
  )

  return { session, role }
}

export default defineNuxtPlugin(async () => {
  let client: Client
  const nuxtApp: NuxtApp = useNuxtApp()

  try {
    client = await getClient()
  } catch (e) {
    nuxtApp.$logger.error(e)
  }

  // exported graphql query function
  const query = async (q: any, variables: any = {}): Promise<Promise<OperationResult<any>> | void> => {
    try {
      return await client.query(q, variables)
    } catch (e) {
      nuxtApp.$logger.error(e)
      nuxtApp.$logger.info(q)
      await new Promise(resolve => setTimeout(resolve, 1000)) // wait before retrying
    }
  }

  // exported graphql subscription function
  const sub = (q: any, variables = {}, onNext: (data: any) => void): Subscription | void => {
    try {
      return pipe(
        client.subscription(q, variables),
        subscribe((result) => {
          onNext(result)
        })
      )
    } catch (e) {
      nuxtApp.$logger.error(e)
    }
  }

  const mutate = (q: any, variables = {}): Promise<OperationResult<any, {}>> => {
    return client.mutation(q, variables).toPromise()
  }

  const gql = { query, sub, mutate }

  return {
    provide: {
      gql
    }
  }
})

function getExchanges (authData: AuthData): Exchange[] {
  const envVars = useRuntimeConfig()
  const nuxtApp: NuxtApp = useNuxtApp()

  let session = authData.session
  let role = authData.role
  let isWsSupported = false
  let wsErrorToastShown = false

  const retryExchangeOptions = {
    initialDelayMs: 1000,
    maxDelayMs: 15000,
    randomDelay: true,
    maxNumberAttempts: 3
  }

  const auth = authExchange(async (utilities: AuthUtilities) => {
    const authenticationData = await initializeAuthState()
    session = authenticationData.session
    role = authenticationData.role

    return {
      async refreshAuth () {
        const authenticationData = await initializeAuthState()
        session = authenticationData.session
        role = authenticationData.role
      },
      didAuthError (error: CombinedError, _operation: any) {
        if (error?.networkError) {
          nuxtApp.$logger.info('network error')
          nuxtApp.$logger.info(error.networkError)
          nuxtApp.$logger.info(`is auth error? ${error.networkError?.reason?.includes('JWTExpired')}`)
          return error.networkError?.reason?.includes('JWTExpired')
        }

        if (error?.graphQLErrors) {
          nuxtApp.$logger.info('graphql error')
          nuxtApp.$logger.info(error.graphQLErrors)
        }

        return false
      },
      // check before a request is made ifh auth will error
      willAuthError (_operation) {
        const decoded = jwt_decode(session.value.accessToken)
        return Date.now() > decoded?.exp * 1000
      },
      addAuthToOperation (operation: Operation) {
        return session.value.accessToken
          ? utilities.appendHeaders(operation, {
            Authorization: `Bearer ${session.value.accessToken}`,
            'x-hasura-role': role
          })
          : operation
      }
    }
  })

  let exchanges: Exchange[] = []
  if (envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') {
    exchanges = [devtoolsExchange, auth, retryExchange(retryExchangeOptions), fetchExchange]
  } else {
    exchanges = [auth, retryExchange(retryExchangeOptions), fetchExchange]
  }

  if (process.client) {
    const subscriptionClient = createWSClient({
      url: envVars.public.HASURA_WS_ENDPOINT,
      lazy: true,
      retryAttempts: 3,
      connectionParams: async () => {
        const authenticationData = await initializeAuthState()
        return {
          headers: {
            Authorization: `Bearer ${authenticationData.session.value.accessToken}`,
            'x-hasura-role': authenticationData.role || ''
          }
        }
      },
      on: {
        connected: () => {
          isWsSupported = true
          nuxtApp.$logger.info('ws connected')
        },
        closed: () => {
          if (!isWsSupported && !wsErrorToastShown) {
            // we were never connected, so probably not supported
            nuxtApp.$toast.error('Fehler beim Verbindungsaufbau', 'Konnte kein Websocket öffnen, vermutlich sind diese nicht unterstützt oder wurden blockiert')
            wsErrorToastShown = true // only show once
          }

          nuxtApp.$logger.info('ws closed') // connection rejected, probably not supported
        },
        disconnected: () => {
          nuxtApp.$logger.info('ws discconnected')
        },
        // eslint-disable-next-line require-await
        error: async (error) => {
          nuxtApp.$logger.error(error)
        },
        reconnected: () => {
          nuxtApp.$logger.info('ws reconnected')
        },
        reconnecting: () => {
          nuxtApp.$logger.info('ws reconnecting')
        }
      }
    })

    exchanges.push(
      subscriptionExchange({
        forwardSubscription (request: SubscriptionOperation) {
          const input = { ...request, query: request.query || '' }
          return {
            subscribe (sink) {
              const unsubscribe = subscriptionClient.subscribe(input, sink)
              return { unsubscribe }
            }
          }
        }
      })
    )
  }
  return exchanges
}

async function getClient (): Promise<Client> {
  const envVars = useRuntimeConfig()
  const authData = await initializeAuthState()

  return createClient({
    url: envVars.public.HASURA_HTTP_ENDPOINT || '',
    exchanges: getExchanges(authData)
  })
}
