import '@/assets/css/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import type { CookieConsentConfig } from 'vanilla-cookieconsent'

// save in db if a user is logged in
function logConsent () {
  // Retrieve all the fields
  const cookie = CookieConsent.getCookie()
  const preferences = CookieConsent.getUserPreferences()

  // In this example we're saving only 4 fields
  const userConsent = {
    consentId: cookie.consentId,
    acceptType: preferences.acceptType,
    acceptedCategories: preferences.acceptedCategories,
    rejectedCategories: preferences.rejectedCategories
  }

  // Send the data to your backend
  // replace "/your-endpoint-url" with your API
  fetch('/your-endpoint-url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userConsent)
  })
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const config: CookieConsentConfig = {
    revision: 1, //  if you need to refresh the consent due to a change your cookie/privacy policy.
    guiOptions: {
      consentModal: {
        layout: 'bar',
        position: 'bottom',
        equalWeightButtons: false,
        flipButtons: false
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: false,
        flipButtons: false
      }
    },
    categories: {
      necessary: {
        readOnly: true,
        enabled: true
      },
      functionality: {},
      analytics: {}
    },
    language: {
      default: 'de',
      autoDetect: 'browser',
      translations: {
        de: {
          consentModal: {
            title: 'Hinweis zu Cookies und Diensten',
            description: 'Wir möchten Cookies dafür nutzen, unsere Website zu analysieren und sie zu verbessern, sowie für Werbezwecke.',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            showPreferencesBtn: 'Einstellungen verwalten',
            revisionMessage: 'Änderungen an den Datenschutzrichtlinien',
            footer: '<a href="https://sec-auditor.com/datenschutz">Datenschutz</a>  <a href="https://sec-auditor.com/impressum">Impressum</a>'
          },
          preferencesModal: {
            title: 'Präferenzen für die Zustimmung',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            savePreferencesBtn: 'Einstellungen speichern',
            closeIconLabel: 'Modal schließen',
            serviceCounterLabel: 'Dienstleistungen',
            sections: [
              {
                title: 'Notwendige Cookies <span class="pm__badge">Immer Aktiviert</span>',
                description: 'Diese Cookies sind für den Betrieb der Seite notwendig und ermöglichen unter anderem Basis-Funktionen wie Navigation sowie sicherheitsrelevante Funktionalitäten. Die Seite kann ohne diese Cookies nicht fehlerfrei funktionieren.',
                linkedCategory: 'necessary'
              },
              {
                title: 'Statistik',
                description: 'Um unser Angebot und unsere Webseite zu verbessern, erfassen wir anonymisierte Daten für Statistiken und Erhebungen. Mithilfe dieser Cookies können wir zum Beispiel den Effekt von bestimmten Inhalten auf unserer Webseite ermitteln und optimieren.',
                linkedCategory: 'analytics'
              }
            ]
          }
        }
      }
    },

    onFirstConsent: () => {
      handleConsentChange()
    },

    onChange: function () {
      handleConsentChange()
    }
  }

  function handleConsentChange () {
    if (CookieConsent.acceptedCategory('analytics')) {
      // Analytics category enabled
      nuxtApp.$event.emit('consent:sentry', true)
    } else {
      // Analytics category was just disabled
      nuxtApp.$event.emit('consent:sentry', false)
    }

    logConsent()
  }

  await CookieConsent.run(config)

  return {
    provide: {
      CC: CookieConsent
    }
  }
})
