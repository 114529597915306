import {
  captureException,
  captureMessage,
  init,
  setUser,
  addBreadcrumb,
  setTag,
  replayIntegration,
  getCurrentHub
} from '@sentry/vue'
import { useCurrentUserSession } from '~/composables/states'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp
  const { isInternalUser } = useCurrentUserSession()
  const { data: session } = useAuth()

  nuxtApp.$event.listen('consent:sentry', (setting: boolean) => startInitSentry(setting))

  function startInitSentry (setting: boolean) {
    if (isInternalUser) {
      nuxtApp.$logger.info('Sentry disabled for internal users')
      initSentry(0.0, 0.0)
    } else if (setting) {
      // handle consent
      nuxtApp.$logger.info('Sentry enabled trough consent')
      initSentry(1.0, 1.0)
    } else {
      // handle consent
      nuxtApp.$logger.info('Sentry disabled trough consent')
      initSentry(0.0, 0.0)
    }

    // identify user
    setUser({
      id: session?.value?.user?.roles?.['x-hasura-user-id'] || 'no user detected',
      email: session?.value?.user?.email || 'no email detected'
    })
  }

  function initSentry (sessionSampleRate: number, errorSampleRate: number) {
    init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN,
      release: 'web@' + config.public.VERSION,
      enabled: config.public.ENVIRONMENT !== 'dev',
      environment: config.public.ENVIRONMENT,
      initialScope: {
        tags: { scope: 'app' }
      },
      integrations: [
        replayIntegration(
          {
            maskAllText: false,
            blockAllMedia: false
          })
      ],
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      trackComponents: true,
      replaysOnErrorSampleRate: errorSampleRate,
      replaysSessionSampleRate: sessionSampleRate
    })
  }

  return {
    provide: {
      sentry: {
        captureException,
        captureMessage,
        addBreadcrumb,
        setTag,
        flush: async (): Promise<void> => {
          const replay = getCurrentHub().getIntegration(Replay)
          if (replay) {
            await replay.flush()
          }
        }
      }
    }
  }
})
