import { defineNuxtPlugin } from 'nuxt/app'
import { useNotifications } from '~/composables/states'

export default defineNuxtPlugin(() => {
  const { addNotification } = useNotifications()

  function error (toastTitle: string, text: string): void {
    addNotification({
      type: 'error',
      duration: 8000,
      title: toastTitle,
      text
    })
  }

  function warn (toastTitle: string, text: string): void {
    addNotification({
      type: 'warn',
      duration: 6000,
      title: toastTitle,
      text
    })
  }

  function success (toastTitle: string, text: string): void {
    addNotification({
      type: 'success',
      duration: 6000,
      title: toastTitle,
      text
    })
  }

  const toast = { error, warn, success }
  return {
    provide: {
      toast
    }
  }
})
