import { defineNuxtPlugin, type NuxtApp } from 'nuxt/app'
import { type RuntimeConfig } from '@nuxt/schema'

export default defineNuxtPlugin(() => {
  const envVars: RuntimeConfig = useRuntimeConfig()
  const nuxtApp: NuxtApp = useNuxtApp()

  function error (e: any): void {
    if (e instanceof Error) {
      nuxtApp.$sentry.captureException(e)
    } else {
      nuxtApp.$sentry.captureMessage(e)
    }

    if (envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  function warn (e: any): void {
    if (e instanceof Error) {
      nuxtApp.$sentry.captureException(e)
    } else {
      nuxtApp.$sentry.captureMessage(e)
    }

    if (envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') {
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  }

  function success (e: any): void {
    if (envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  function info (e: any): void {
    if (envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev') {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const logger = { error, warn, success, info }
  return {
    provide: {
      logger
    }
  }
})
