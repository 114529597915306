import SessionData from '@sidebase/nuxt-auth/dist/types'
import { type Ref } from 'vue'
import { useState } from '#app'
import type { ModuleOptions, NuxtModule } from 'nuxt/schema'
import type { Session } from 'types/next-auth'
import { type SecNotification } from '~/types/other'
import { getMainRole } from '~/utils/other'

export const useCurrentClient = (): {
  getCurrentClientUUID: () => string;
  setCurrentClientUUID: (uuid: string) => void;
  currentClient: Ref<{ client_mode: string; client_address: null; client_name: string; client_id_pk: string; client_initialized: boolean }>;
  getCurrentClientIdToSchema: () => string;
  setCurrentClient: (c: any) => void;
  initializeCurrentClient: (uuid: string) => Promise<void>;
} => {
  const { $gql } = useNuxtApp()
  const currentClient = useState('currentClient', () => {
    return {
      client_id_pk: '',
      client_name: '',
      client_address: null,
      client_mode: '',
      client_initialized: false
    }
  })

  const setCurrentClient = (c: any): void => {
    currentClient.value = c
  }

  const fetchCurrentClient = async (uuid: string): Promise<void> => {
    const currentClientQuery = `
      query client($clientUUID: uuid!) {
        tbl_client(where: { client_id_pk: { _eq: $clientUUID } }) {
          client_name
          client_id_pk
          client_mode
          client_address
          client_initialized
        }
      }
    `

    const { data } = await $gql.query(currentClientQuery, {
      clientUUID: uuid
    })

    setCurrentClient(data.tbl_client[0])
  }

  const setCurrentClientUUID = (uuid: string): void => {
    currentClient.value.client_id_pk = uuid.replace(/_/g, '-')
  }

  const getCurrentClientIdToSchema = (): string => {
    return currentClient.value.client_id_pk.replace(/-/g, '_')
  }

  const getCurrentClientUUID = (): string => {
    if (currentClient.value.client_id_pk) {
      return currentClient.value.client_id_pk
    } else {
      return ''
    }
  }

  return {
    initializeCurrentClient: fetchCurrentClient,
    setCurrentClient,
    currentClient,
    setCurrentClientUUID,
    getCurrentClientIdToSchema,
    getCurrentClientUUID
  }
}

export const useCurrentIoe = (): {
    currentIoe: Ref<any>;
    setCurrentIoe: (ioe: any) => void;
} => {
  const currentIoe = useState('currentIoe', () => {
  })

  const setCurrentIoe = (ioe: any): void => {
    currentIoe.value = ioe
  }

  return {
    currentIoe,
    setCurrentIoe
  }
}

export const useClientTimezone = (): {
  clientTimezone: Ref<string>;
  setClientTimezone: (timeZone: string) => void;
} => {
  const clientTimezone = useState('clientTimezone', () => 'Europe/Berlin')

  const setClientTimezone = (timeZone: string): void => {
    clientTimezone.value = timeZone
  }

  return {
    clientTimezone,
    setClientTimezone
  }
}

export const useNotifications = (): {
    notifications: Ref<SecNotification[]>;
    addNotification: (notification: SecNotification) => void;
    removeNotification: (uuid: number) => void
} => {
  const notifications: Ref<SecNotification[]> = useState('notifications', () => [])

  const addNotification = (notification: SecNotification): void => {
    const newNotification = {
      ...notification,
      id: notifications.value.length + 1
    }

    // add to notification queue
    notifications.value.push(newNotification)

    // set timer to remove the notification
    setTimeout(function () {
      removeNotification(newNotification.id)
    }, newNotification.duration)
  }

  const removeNotification = (notificationId: number): void => {
    notifications.value = notifications.value.filter(
      n => n.id !== notificationId
    )
  }

  return {
    notifications,
    addNotification,
    removeNotification
  }
}

export const useCurrentServiceprovider = (): {
  serviceprovider: Ref<{ uuid: string; freeSeats: number; name: string, hasLogo: boolean, paymentDetailsRequired: boolean, dataLoaded: boolean }>;
  getServiceproviderIdToSchema: () => string;
  setServiceproviderUUID: (uuid: string) => void;
  setServiceprovider: (sp: { uuid: string; freeSeats: number, hasLogo: boolean, paymentDetailsRequired: boolean }) => void;
  setServiceproviderName: (name: string) => void
} => {
  const serviceprovider: Ref<{ uuid: string, freeSeats: number, name: string, hasLogo: boolean, paymentDetailsRequired: boolean, dataLoaded: boolean }> = useState('useServiceprovider', () => { return { uuid: '', freeSeats: 2, name: '', hasLogo: false, dataLoaded: false, paymentDetailsRequired: false } })

  const setServiceproviderUUID = (uuid: string): void => {
    serviceprovider.value.uuid = uuid
  }
  const setServiceproviderName = (name: string): void => {
    serviceprovider.value.name = name
  }

  const setServiceprovider = (sp: { uuid: string, freeSeats: number, hasLogo: boolean, paymentDetailsRequired: boolean, }): void => {
    // if already set, use the existing name, otherwise overwrite
    serviceprovider.value = {
      name: serviceprovider.value.name ?? '',
      uuid: sp.uuid,
      freeSeats: sp.freeSeats,
      hasLogo: sp.hasLogo,
      paymentDetailsRequired: sp.paymentDetailsRequired,
      dataLoaded: true
    }
  }

  const getServiceproviderIdToSchema = (): string => {
    if (serviceprovider.value.uuid) {
      return serviceprovider.value.uuid.replace(/-/g, '_')
    } else {
      return ''
    }
  }

  return {
    serviceprovider,
    setServiceprovider,
    setServiceproviderUUID,
    setServiceproviderName,
    getServiceproviderIdToSchema
  }
}

export const useIoeConfig = (): {
  ioeConfig: Ref<any>;
  setIoeConfig: (config: any) => void;
} => {
  const ioeConfig = useState('ioeConfig', () => {
  })

  const setIoeConfig = (config: any): void => {
    ioeConfig.value = config
  }

  return {
    ioeConfig,
    setIoeConfig
  }
}

export const useCurrentUserSession = (): {
  mainRole: string;
  currentEnv: { isProdEnv: boolean; isNextEnv: boolean; isDemoEnv: boolean };
  setCurrentUserSession: (s: NuxtModule<ModuleOptions>) => void;
  isInternalUser: boolean;
  userOrganization: any;
  isAdminUser: any;
  currentUserSession: Ref<Session | null | undefined>;
  isServiceproviderUser: any
} => {
  const { data } = useAuth()
  const currentUserSession = useState('useCurrentUser', () => data)

  const setCurrentUserSession = (s: typeof SessionData): void => {
    currentUserSession.value = s
  }

  const isServiceproviderUser = currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'serviceprovider'
  )

  const isAdminUser = currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'admin'
  )

  const envVars = useRuntimeConfig()
  const isDemoEnv = envVars.public.ENVIRONMENT === 'demo'
  const isNextEnv = envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev'
  const isProdEnv = envVars.public.ENVIRONMENT === 'prod'
  const currentEnv = {
    isDemoEnv,
    isNextEnv,
    isProdEnv
  }

  const internalEmailDomains = ['sec-auditor.com', 'thomaskoscheck.de']
  const userEmailDomain = currentUserSession?.value?.user?.email?.split('@')[1] || ''
  const isInternalUser = internalEmailDomains.includes(userEmailDomain)

  // removes all extra roles to return one of the three main roles
  // client, serviceprovider or admin
  const mainRole = getMainRole(currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'])
  const userOrganization = currentUserSession?.value?.user?.roles?.['x-hasura-user-org']

  return {
    currentUserSession,
    mainRole,
    isInternalUser,
    currentEnv,
    userOrganization,
    setCurrentUserSession,
    isServiceproviderUser,
    isAdminUser
  }
}
